
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue"
  import AgencyFormEdit from "@/components/shared/AgencyFormEdit.vue"
  import { Agency } from "@/models/agencies"
  import { CollectionResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { provideRefreshData } from "@/utils/refreshData"

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      AgencyFormEdit,
      TitleActionsNavbar,
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const agency = computed<ResourceObject<string, Agency>>(() => root.$store.state.agencies.element)


      const refreshData = () => {
        root.$store.dispatch('agencies/get', { id: props.id, queryParams: { include: 'company_address' } })
      }

      provideRefreshData(refreshData)
      refreshData()

      const title = computed(() => {
        // let d = careReceiver.value ? careReceiver.value.attributes?.designation : ''
        // return `Haushalt ${d}`
        return 'Agency'
      })

      const titleActions = ref([
        {
          title: 'Bearbeiten',
          tooltip: 'Bearbeiten',
          icon: 'pencil-alt',
          onClick: () => alert('Edit')
        },
      ])

      return {
        agency,
        title,
        titleActions,
      }
    }
  })
